body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--greenNew);
  padding-right: 10px;
  padding-left: 10px;
}
:root{
  --blueFlit: #0d3857;
  --greenFlit: #007473;
  --lightGreenFlit: #65b76f;
  --inputgrey: #ebebeb;
  --strava: #fc4c02;
  --garmin: #000;
  --beige:#F6F3F3;
  --grey:#8A9CAB;
  --greenNew:#DFF0DF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
