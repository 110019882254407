.events {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.events_logo{
    width: 60%;
}
.events_list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
    align-items: center;
}

.events_list_item {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.events_list_input {
    border: none;
    border-radius: 20px;
    padding-left: 10px;
    width: 80%;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    outline: none;
}

.events_list_input_label {
    border: none;
    border-radius: 20px;
    padding-left: 10px;
    width: 80%;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
}

.events_list_input_password {
    border: none;
    border-radius: 30px;
    outline: none;
    width: 80%;

}

.events_eyes {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 20px;
}

.events_button {
    background: linear-gradient(
            90deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    width: 50%;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    border: none;
    color: white;
    height: 30px;
}

/* REDIRECT */

.events_redirect_global {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}

.events_logo_redirect {
    width: 60%;
}

.events_logo_redirect_android {
    width: 60%;
}

.events_redirect_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (min-width: 1024px) {
    .events {
        width: 50%;
        margin: auto;
    }
    .events_logo{
        width: 40%;
    }

    .events_logo_redirect {
        width: 30%;
    }

    .events_logo_redirect_android {
        width: 30%;
    }

    .events_list_input {

        width: 50%;

    }

    .events_list_input_label {

        width: 50%;

    }
    .events_button{
        width: 30%;
    }

}